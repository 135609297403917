<template>
  <b-overlay
    :show="OverLay"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card>
      <div class="d-flex">
        <b-button
          v-for="(tab, index) in tabs"
          :key="index"
          variant="outline-primary"
          class="mr-1"
          :class="{ active: currentTab === index }"
          @click="currentTab = index"
        >{{ tab }}
        </b-button>
      </div>

      <div
        v-show="currentTab === 0"
        class="mt-1"
      >
        <table class="table table-hover table-bordered">
          <colgroup>
            <col width="60%">
            <col width="40%">
          </colgroup>
          <thead>
            <tr>
              <th
                colspan="4"
                class="text-center"
              >
                ตั้งค่าเปิด/ปิดกลุ่มหวย
              </th>
            </tr>
            <tr class="text-center">
              <th>กลุ่มหวย</th>
              <th>เปิด/ปิด</th>
            </tr>
          </thead>
          <tbody>
            <tr style="background: #b4deffd9">
              <td>ตั้งค่าหวยทั้งหมด</td>
              <td class="text-center">
                <b-form-checkbox
                  v-model="huay_status"
                  value="1"
                  unchecked-value="0"
                  switch
                  @change="AllSetGroup()"
                >
                  {{ huay_status ? 'เปิด' : 'ปิด' }}
                </b-form-checkbox>
              </td>
            </tr>
            <tr
              v-for="(ItGr, index) in LottoGroup"
              :key="index"
            >
              <td>{{ ItGr.group_name }}</td>
              <td class="text-center">
                <b-form-checkbox
                  v-model="ItGr.status"
                  value="1"
                  unchecked-value="0"
                  switch
                >
                  {{ ItGr.status === '1' ? 'เปิด' : 'ปิด' }}
                </b-form-checkbox>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        v-show="currentTab === 1"
        class="mt-1"
      >
        <div>
          <label
            for="set-groub"
            class="font-weight-bolder"
          >เลือกกลุ่มหวย</label>
          <div class="d-flex mb-1">
            <button
              class="btn btn-sm btn-primary mr-1"
              @click="SelectTangAll()"
            >
              เลือกทั้งหมด
            </button>
            <button
              class="btn btn-sm btn-primary mr-1"
              @click="TangArr = []"
            >
              ยกเลิกทั้งหมด
            </button>
            <button
              class="btn btn-sm btn-warning mr-1"
              @click="SettingTangAll = !SettingTangAll,TangArr = []"
            >
              ตั้งค่าทั้งหมด
            </button>
          </div>
          <v-select
            v-model="TangArr"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            class="select-size-lg"
            label="group_name"
            :options="LottoGroup"
          />
        </div>
        <div v-if="SettingTangAll">
          <h3 class="font-weight-bolder font-medium-3 mt-1">
            ตั้งค่าข้อมูลการแทงทั้งหมด
          </h3>
          <table class="table table-bordered table-sm table-hover">
            <thead class="bg-primary thead-dark">
              <tr>
                <th
                  scope="col"
                  rowspan="2"
                  class="text-center align-middle"
                >
                  ประเภท
                </th>
                <th colspan="4">
                  <button
                    type="button"
                    class="btn mr-1 tx-13 btn-primary btn-sm"
                  >
                    เลขท้าย
                  </button><button
                    type="button"
                    class="btn mr-1 tx-13 btn-light btn-sm"
                  >
                    เลขหัว
                  </button><button
                    type="button"
                    class="btn mr-1 tx-13 btn-light btn-sm"
                  >
                    เลข 4-6 ตัว
                  </button><button
                    type="button"
                    class="btn mr-1 tx-13 btn-light btn-sm"
                  >
                    เลขคู่คี่
                  </button>
                </th>
              </tr>
              <tr>
                <th
                  scope="col"
                  class="text-center align-middle"
                >
                  ขั้นต่ำ
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm"
                    @click="ButtonMax(['min_u3','min_d3','min_t3','min_u2','min_d2','min_u1','min_d1'])"
                  >
                    ตั้งสูงสุด
                  </button>
                </th>
                <th
                  scope="col"
                  class="text-center align-middle"
                >
                  สูงสุด
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm"
                    @click="ButtonMax(['max_u3','max_d3','max_t3','max_u2','max_d2','max_u1','max_d1','max_t1'])"
                  >
                    ตั้งสูงสุด
                  </button>
                </th>
                <th
                  scope="col"
                  class="text-center align-middle"
                >
                  สูงสุดต่อเลข
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm"
                    @click="ButtonMax(['maxnum_u3','maxnum_d3','maxnum_t3','maxnum_u2','maxnum_d2','maxnum_u1','maxnum_d1'])"
                  >
                    ตั้งสูงสุด
                  </button>
                </th>
                <th
                  scope="col"
                  class="text-center align-middle"
                >
                  สูงสุดต่อครั้ง
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm"
                    @click="ButtonMax(['maxtime_u3','maxtime_d3','maxtime_t3','maxtime_u2','maxtime_d2','maxtime_u1','maxtime_d1'])"
                  >
                    ตั้งสูงสุด
                  </button>
                </th>
              </tr>
              <tr>
                <th class="text-center align-middle" />
                <th class="text-left py-2">
                  <input
                    v-model="SettingDetailAll.Minall"
                    type="number"
                    min="0"
                    class="form-control"
                    @input="SettingDetailAll.Minall = CalAutopushAll(SettingDetailAll.Minall ,[ 'min_u3','min_d3','min_t3','min_u2','min_d2','min_u1','min_d1'])"
                  >
                </th>
                <th class="text-left py-2">
                  <input
                    v-model="SettingDetailAll.Maxall"
                    type="number"
                    min="0"
                    class="form-control"
                    @input="SettingDetailAll.Maxall = CalAutopushAll(SettingDetailAll.Maxall ,['max_u3','max_d3','max_t3','max_u2','max_d2','max_u1','max_d1'])"
                  >
                </th>
                <th class="text-left py-2">
                  <input
                    v-model="SettingDetailAll.MaxNumall"
                    type="number"
                    min="0"
                    class="form-control"
                    @input="SettingDetailAll.MaxNumall = CalAutopushAll(SettingDetailAll.MaxNumall ,[ 'maxnum_u3','maxnum_d3','maxnum_t3','maxnum_u2','maxnum_d2','maxnum_u1','maxnum_d1'])"
                  >
                </th>
                <th class="text-left py-2">
                  <input
                    v-model="SettingDetailAll.MaxTimeall"
                    type="number"
                    min="0"
                    class="form-control"
                    @input="SettingDetailAll.MaxTimeall = CalAutopushAll(SettingDetailAll.MaxTimeall ,[ 'maxtime_u3','maxtime_d3','maxtime_t3','maxtime_u2','maxtime_d2','maxtime_u1','maxtime_d1'])"
                  >
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  scope="row"
                  class="text-center align-middle"
                >
                  3 ตัวบน
                </td>
                <td class="text-left py-2">
                  <input
                    v-model="SettingDetail.min_u3"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.min_u3 = CalMinMax(SettingDetail.min_u3 , 0 , FindMinAll(FixRate,'min_u3'))"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    v-model="SettingDetail.max_u3"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.max_u3 = CalMinMax(SettingDetail.max_u3 , 0 , FindMinAll(FixRate,'max_u3'))"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    v-model="SettingDetail.maxnum_u3"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.maxnum_u3 = CalMinMax(SettingDetail.maxnum_u3 , 0 , FindMinAll(FixRate,'maxnum_u3'))"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    v-model="SettingDetail.maxtime_u3"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.maxtime_u3 = CalMinMax(SettingDetail.maxtime_u3 , 0 , FindMinAll(FixRate,'maxtime_u3'))"
                  >
                </td>
              </tr>
              <tr>
                <td
                  scope="row"
                  class="text-center align-middle"
                >
                  3 ตัวล่าง
                </td>
                <td class="text-left py-2">
                  <input
                    v-model="SettingDetail.min_d3"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.min_d3 = CalMinMax(SettingDetail.min_d3 , 0 , FindMinAll(FixRate,'min_d3'))"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    v-model="SettingDetail.max_d3"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.max_d3 = CalMinMax(SettingDetail.max_d3 , 0 , FindMinAll(FixRate,'max_d3'))"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    v-model="SettingDetail.maxnum_d3"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.maxnum_d3 = CalMinMax(SettingDetail.maxnum_d3 , 0 , FindMinAll(FixRate,'maxnum_d3'))"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    v-model="SettingDetail.maxtime_d3"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.maxtime_d3 = CalMinMax(SettingDetail.maxtime_d3 , 0 , FindMinAll(FixRate,'maxtime_d3'))"
                  >
                </td>
              </tr>
              <tr>
                <td
                  scope="row"
                  class="text-center align-middle"
                >
                  3 ตัวโต๊ด
                </td>
                <td class="text-left py-2">
                  <input
                    v-model="SettingDetail.min_t3"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.min_t3 = CalMinMax(SettingDetail.min_t3 , 0 , FindMinAll(FixRate,'min_t3'))"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    v-model="SettingDetail.max_t3"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.max_t3 = CalMinMax(SettingDetail.max_t3 , 0 , FindMinAll(FixRate,'max_t3'))"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    v-model="SettingDetail.maxnum_t3"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.maxnum_t3 = CalMinMax(SettingDetail.maxnum_t3 , 0 , FindMinAll(FixRate,'maxnum_t3'))"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    v-model="SettingDetail.maxtime_t3"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.maxtime_t3 = CalMinMax(SettingDetail.maxtime_t3 , 0 , FindMinAll(FixRate,'maxtime_t3'))"
                  >
                </td>
              </tr>
              <tr>
                <td
                  scope="row"
                  class="text-center align-middle"
                >
                  2 ตัวบน
                </td>
                <td class="text-left py-2">
                  <input
                    v-model="SettingDetail.min_u2"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.min_u2 = CalMinMax(SettingDetail.min_u2 , 0 , FindMinAll(FixRate,'min_u2'))"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    v-model="SettingDetail.max_u2"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.max_u2 = CalMinMax(SettingDetail.max_u2 , 0 , FindMinAll(FixRate,'max_u2'))"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    v-model="SettingDetail.maxnum_u2"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.maxnum_u2 = CalMinMax(SettingDetail.maxnum_u2 , 0 , FindMinAll(FixRate,'maxnum_u2'))"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    v-model="SettingDetail.maxtime_u2"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.maxtime_u2 = CalMinMax(SettingDetail.maxtime_u2 , 0 , FindMinAll(FixRate,'maxtime_u2'))"
                  >
                </td>
              </tr>
              <tr>
                <td
                  scope="row"
                  class="text-center align-middle"
                >
                  2 ตัวล่าง
                </td>
                <td class="text-left py-2">
                  <input
                    v-model="SettingDetail.min_d2"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.min_d2 = CalMinMax(SettingDetail.min_d2 , 0 , FindMinAll(FixRate,'min_d2'))"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    v-model="SettingDetail.max_d2"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.max_d2 = CalMinMax(SettingDetail.max_d2 , 0 , FindMinAll(FixRate,'max_d2'))"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    v-model="SettingDetail.maxnum_d2"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.maxnum_d2 = CalMinMax(SettingDetail.maxnum_d2 , 0 , FindMinAll(FixRate,'maxnum_d2'))"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    v-model="SettingDetail.maxtime_d2"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.maxtime_d2 = CalMinMax(SettingDetail.maxtime_d2 , 0 , FindMinAll(FixRate,'maxtime_d2'))"
                  >
                </td>
              </tr>
              <tr>
                <td
                  scope="row"
                  class="text-center align-middle"
                >
                  วิ่งบน
                </td>
                <td class="text-left py-2">
                  <input
                    v-model="SettingDetail.min_u1"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.min_u1 = CalMinMax(SettingDetail.min_u1 , 0 , FindMinAll(FixRate,'min_u1'))"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    v-model="SettingDetail.max_u1"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.max_u1 = CalMinMax(SettingDetail.max_u1 , 0 , FindMinAll(FixRate,'max_u1'))"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    v-model="SettingDetail.maxnum_u1"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.maxnum_u1 = CalMinMax(SettingDetail.maxnum_u1 , 0 , FindMinAll(FixRate,'maxnum_u1'))"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    v-model="SettingDetail.maxtime_u1"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.maxtime_u1 = CalMinMax(SettingDetail.maxtime_u1 , 0 , FindMinAll(FixRate,'maxtime_u1'))"
                  >
                </td>
              </tr>
              <tr>
                <td
                  scope="row"
                  class="text-center align-middle"
                >
                  วิ่งล่าง
                </td>
                <td class="text-left py-2">
                  <input
                    v-model="SettingDetail.min_d1"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.min_d1 = CalMinMax(SettingDetail.min_d1 , 0 , FindMinAll(FixRate,'min_d1'))"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    v-model="SettingDetail.max_d1"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.max_d1 = CalMinMax(SettingDetail.max_d1 , 0 , FindMinAll(FixRate,'max_d1'))"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    v-model="SettingDetail.maxnum_d1"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.maxnum_d1 = CalMinMax(SettingDetail.maxnum_d1 , 0 , FindMinAll(FixRate,'maxnum_d1'))"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    v-model="SettingDetail.maxtime_d1"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.maxtime_d1 = CalMinMax(SettingDetail.maxtime_d1 , 0 , FindMinAll(FixRate,'maxtime_d1'))"
                  >
                </td>
              </tr>
              <tr
                class="table-disabled"
                style="display: none;"
              >
                <td
                  scope="row"
                  class="text-center align-middle "
                >
                  2 ตัวโต๊ด
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="!SettingTangAll">
          <div
            v-for="(items, index) in TangArr"
            :key="index"
          >
            <h3 class="font-weight-bolder font-medium-3 mt-1">
              {{ items.group_name }}
            </h3>
            <table class="table table-bordered table-sm table-hover">
              <thead>
                <tr>
                  <th
                    scope="col"
                    rowspan="2"
                    class="text-center align-middle"
                  >
                    ประเภท
                  </th>
                  <th colspan="4">
                    <button
                      type="button"
                      class="btn mr-1 tx-13 btn-primary btn-sm"
                    >
                      เลขท้าย
                    </button>
                    <button
                      type="button"
                      class="btn mr-1 tx-13 btn-primary btn-sm"
                    >
                      เลขหัว
                    </button>
                    <button
                      type="button"
                      class="btn mr-1 tx-13 btn-primary btn-sm"
                    >
                      เลข 4-6 ตัว
                    </button>
                    <button
                      type="button"
                      class="btn mr-1 tx-13 btn-primary btn-sm"
                    >
                      เลขคู่คี่
                    </button>
                  </th>
                </tr>
                <tr>
                  <th
                    scope="col"
                    class="text-center align-middle"
                  >
                    ขั้นต่ำ
                  </th>
                  <th
                    scope="col"
                    class="text-center align-middle"
                  >
                    สูงสุด
                  </th>
                  <th
                    scope="col"
                    class="text-center align-middle"
                  >
                    สูงสุดต่อเลข
                  </th>
                  <th
                    scope="col"
                    class="text-center align-middle"
                  >
                    สูงสุดต่อครั้ง
                  </th>
                </tr>
                <tr>
                  <th class="text-center align-middle" />
                  <th class="text-left py-2">
                    <input
                      v-model="SettingDetailTools[index].Minall"
                      type="number"
                      min="0"
                      class="form-control "
                      @input="SettingDetailTools[index].Minall = CalAutopush(SettingDetailTools[index].Minall,index ,['min_u3','min_d3','min_t3','min_u2','min_d2','min_u1','min_d1'])"
                    >
                  </th>
                  <th class="text-left py-2">
                    <input
                      v-model="SettingDetailTools[index].Maxall"
                      type="number"
                      min="0"
                      class="form-control "
                      @input="SettingDetailTools[index].Maxall = CalAutopush(SettingDetailTools[index].Maxall,index ,['max_u3','max_d3','max_t3','max_u2','max_d2','max_u1','max_d1'])"
                    >

                  </th>
                  <th class="text-left py-2">
                    <input
                      v-model="SettingDetailTools[index].MaxNumall"
                      type="number"
                      min="0"
                      class="form-control "
                      @input="SettingDetailTools[index].MaxNumall = CalAutopush(SettingDetailTools[index].MaxNumall,index ,['maxnum_u3','maxnum_d3','maxnum_t3','maxnum_u2','maxnum_d2','maxnum_u1','maxnum_d1'])"
                    >
                  </th>
                  <th class="text-left py-2">
                    <input
                      v-model="SettingDetailTools[index].MaxTimeall"
                      type="number"
                      min="0"
                      class="form-control "
                      @input="SettingDetailTools[index].MaxTimeall = CalAutopush(SettingDetailTools[index].MaxTimeall,index ,['maxtime_u3','maxtime_d3','maxtime_t3','maxtime_u2','maxtime_d2','maxtime_u1','maxtime_d1'])"
                    >
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="">
                  <td
                    scope="row"
                    class="text-center align-middle"
                  >
                    3 ตัวบน
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__775"
                        v-model="items.min_u3"
                        type="number"
                        min="0"
                        max="10"
                        class="form-control"
                        @input="items.min_u3 = CalMinMax(items.min_u3 , 0 , FixRate[index].min_u3)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].min_u3 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__776"
                        v-model="items.max_u3"
                        type="number"
                        min="0"
                        max="100000"
                        class="form-control"
                        @input="items.max_u3 = CalMinMax(items.max_u3 , 0 , FixRate[index].max_u3)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].max_u3 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__777"
                        v-model="items.maxnum_u3"
                        type="number"
                        min="0"
                        max="100000"
                        class="form-control"
                        @input="items.maxnum_u3 = CalMinMax(items.maxnum_u3 , 0 , FixRate[index].maxnum_u3)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].maxnum_u3 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__777"
                        v-model="items.maxtime_u3"
                        type="number"
                        min="0"
                        max="100000"
                        class="form-control"
                        @input="items.maxtime_u3 = CalMinMax(items.maxtime_u3 , 0 , FixRate[index].maxtime_u3)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].maxtime_u3 }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr class="">
                  <td
                    scope="row"
                    class="text-center align-middle"
                  >
                    3 ตัวล่าง
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__760"
                        v-model="items.min_d3"
                        type="number"
                        min="0"
                        max="1"
                        class="form-control"
                        @input="items.min_d3 = CalMinMax(items.min_d3 , 0 , FixRate[index].min_d3)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].min_d3 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__761"
                        v-model="items.max_d3"
                        type="number"
                        min="0"
                        max="100000"
                        class="form-control"
                        @input="items.max_d3 = CalMinMax(items.max_d3 , 0 , FixRate[index].max_d3)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].max_d3 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__762"
                        v-model="items.maxnum_d3"
                        type="number"
                        min="0"
                        max="100000"
                        class="form-control"
                        @input="items.maxnum_d3 = CalMinMax(items.maxnum_d3 , 0 , FixRate[index].maxnum_d3)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].maxnum_d3 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__762"
                        v-model="items.maxtime_d3"
                        type="number"
                        min="0"
                        max="100000"
                        class="form-control"
                        @input="items.maxtime_d3 = CalMinMax(items.maxtime_d3 , 0 , FixRate[index].maxtime_d3)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].maxtime_d3 }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr class="">
                  <td
                    scope="row"
                    class="text-center align-middle"
                  >
                    3 ตัวโต๊ด
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__769"
                        v-model="items.min_t3"
                        type="number"
                        min="0"
                        max="1"
                        class="form-control"
                        @input="items.min_t3 = CalMinMax(items.min_t3 , 0 , FixRate[index].min_t3)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].min_t3 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__770"
                        v-model="items.max_t3"
                        type="number"
                        min="0"
                        max="100000"
                        class="form-control"
                        @input="items.max_t3 = CalMinMax(items.max_t3 , 0 , FixRate[index].max_t3)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].max_t3 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__771"
                        v-model="items.maxnum_t3"
                        type="number"
                        min="0"
                        max="100000"
                        class="form-control"
                        @input="items.maxnum_t3 = CalMinMax(items.maxnum_t3 , 0 , FixRate[index].maxnum_t3)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].maxnum_t3 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__771"
                        v-model="items.maxtime_t3"
                        type="number"
                        min="0"
                        max="100000"
                        class="form-control"
                        @input="items.maxtime_t3 = CalMinMax(items.maxtime_t3 , 0 , FixRate[index].maxtime_t3)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].maxtime_t3 }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr class="">
                  <td
                    scope="row"
                    class="text-center align-middle"
                  >
                    2 ตัวบน
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__772"
                        v-model="items.min_u2"
                        type="number"
                        min="0"
                        max="1"
                        class="form-control"
                        @input="items.min_u2 = CalMinMax(items.min_u2 , 0 , FixRate[index].min_u2)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].min_u2 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__773"
                        v-model="items.max_u2"
                        type="number"
                        min="0"
                        max="100000"
                        class="form-control"
                        @input="items.max_u2 = CalMinMax(items.max_u2 , 0 , FixRate[index].max_u2)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].max_u2 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__774"
                        v-model="items.maxnum_u2"
                        type="number"
                        min="0"
                        max="100000"
                        class="form-control"
                        @input="items.maxnum_u2 = CalMinMax(items.maxnum_u2 , 0 , FixRate[index].maxnum_u2)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].maxnum_u2 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__774"
                        v-model="items.maxtime_u2"
                        type="number"
                        min="0"
                        max="100000"
                        class="form-control"
                        @input="items.maxtime_u2 = CalMinMax(items.maxtime_u2 , 0 , FixRate[index].maxtime_u2)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].maxtime_u2 }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr class="">
                  <td
                    scope="row"
                    class="text-center align-middle"
                  >
                    2 ตัวล่าง
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__757"
                        v-model="items.min_d2"
                        type="number"
                        min="0"
                        max="1"
                        class="form-control"
                        @input="items.min_d2 = CalMinMax(items.min_d2 , 0 , FixRate[index].min_d2)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].min_d2 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__758"
                        v-model="items.max_d2"
                        type="number"
                        min="0"
                        max="100000"
                        class="form-control"
                        @input="items.max_d2 = CalMinMax(items.max_d2 , 0 , FixRate[index].max_d2)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].max_d2 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__759"
                        v-model="items.maxnum_d2"
                        type="number"
                        min="0"
                        max="100000"
                        class="form-control"
                        @input="items.maxnum_d2 = CalMinMax(items.maxnum_d2 , 0 , FixRate[index].maxnum_d2)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].maxnum_d2 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__759"
                        v-model="items.maxtime_d2"
                        type="number"
                        min="0"
                        max="100000"
                        class="form-control"
                        @input="items.maxtime_d2 = CalMinMax(items.maxtime_d2 , 0 , FixRate[index].maxtime_d2)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].maxtime_d2 }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr class="">
                  <td
                    scope="row"
                    class="text-center align-middle"
                  >
                    วิ่งบน
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__778"
                        v-model="items.min_u1"
                        type="number"
                        min="0"
                        max="1"
                        class="form-control"
                        @input="items.min_u1 = CalMinMax(items.min_u1 , 0 , FixRate[index].min_u1)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].min_u1 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__779"
                        v-model="items.max_u1"
                        type="number"
                        min="0"
                        max="100000"
                        class="form-control"
                        @input="items.max_u1 = CalMinMax(items.max_u1 , 0 , FixRate[index].max_u1)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].max_u1 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__780"
                        v-model="items.maxnum_u1"
                        type="number"
                        min="0"
                        max="100000"
                        class="form-control"
                        @input="items.maxnum_u1 = CalMinMax(items.maxnum_u1 , 0 , FixRate[index].maxnum_u1)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].maxnum_u1 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__780"
                        v-model="items.maxtime_u1"
                        type="number"
                        min="0"
                        max="100000"
                        class="form-control"
                        @input="items.maxtime_u1 = CalMinMax(items.maxtime_u1 , 0 , FixRate[index].maxtime_u1)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].maxtime_u1 }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr class="">
                  <td
                    scope="row"
                    class="text-center align-middle"
                  >
                    วิ่งล่าง
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__763"
                        v-model="items.min_d1"
                        type="number"
                        min="0"
                        max="1"
                        class="form-control"
                        @input="items.min_d1 = CalMinMax(items.min_d1 , 0 , FixRate[index].min_d1)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].min_d1 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__764"
                        v-model="items.max_d1"
                        type="number"
                        min="0"
                        max="100000"
                        class="form-control"
                        @input="items.max_d1 = CalMinMax(items.max_d1 , 0 , FixRate[index].max_d1)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].max_d1 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__765"
                        v-model="items.maxnum_d1"
                        type="number"
                        min="0"
                        max="100000"
                        class="form-control"
                        @input="items.maxnum_d1 = CalMinMax(items.maxnum_d1 , 0 , FixRate[index].maxnum_d1)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].maxnum_d1 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__765"
                        v-model="items.maxtime_d1"
                        type="number"
                        min="0"
                        max="100000"
                        class="form-control"
                        @input="items.maxtime_d1 = CalMinMax(items.maxtime_d1 , 0 , FixRate[index].maxtime_d1)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].maxtime_d1 }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr
                  class="table-disabled"
                  style="display: none;"
                >
                  <td
                    scope="row"
                    class="text-center align-middle"
                  >
                    2 ตัวโต๊ด
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__766"
                        v-model="items.min_t2"
                        type="number"
                        disabled="disabled"
                        min="0"
                        max="1"
                        class="form-control"
                        @input="items.min_t2 = CalMinMax(items.min_t2 , 0 , FixRate[index].min_t2)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].min_t2 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__767"
                        v-model="items.max_t2"
                        type="number"
                        disabled="disabled"
                        min="0"
                        max="10000"
                        class="form-control"
                        @input="items.max_t2 = CalMinMax(items.max_t2 , 0 , FixRate[index].max_t2)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].max_t2 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__768"
                        v-model="items.maxnum_t2"
                        type="number"
                        disabled="disabled"
                        min="0"
                        max="10000"
                        class="form-control"
                        @input="items.maxnum_t2 = CalMinMax(items.maxnum_t2 , 0 , FixRate[index].maxnum_t2)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].maxnum_t2 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-left py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__768"
                        v-model="items.maxtime_t2"
                        type="number"
                        disabled="disabled"
                        min="0"
                        max="10000"
                        class="form-control"
                        @input="items.maxtime_t2 = CalMinMax(items.maxtime_t2 , 0 , FixRate[index].maxtime_t2)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].maxtime_t2 }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div
        v-show="currentTab === 2"
        class="mt-1"
      >
        <div>
          <label
            for="set-groub"
            class="font-weight-bolder"
          >เลือกกลุ่มหวย</label>
          <div class="d-flex mb-1">
            <button
              class="btn btn-sm btn-primary mr-1"
              @click="SelectRateAll()"
            >
              เลือกทั้งหมด
            </button>
            <button
              class="btn btn-sm btn-primary mr-1"
              @click="RateArr = []"
            >
              ยกเลิกทั้งหมด
            </button>
            <button
              class="btn btn-sm btn-warning mr-1"
              @click="SettingRateAll = !SettingRateAll,RateArr = []"
            >
              ตั้งค่าทั้งหมด
            </button>
          </div>
          <v-select
            v-model="RateArr"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            label="group_name"
            class="select-size-lg"
            :options="LottoGroup"
          />
        </div>

        <!-- setting all -->
        <div
          v-if="SettingRateAll"
          class="mt-1"
        >
          <h3 class="font-weight-bolder font-medium-3 mt-1">
            ตั้งค่าอัตราจ่ายทั้งหมด
          </h3>
          <table class="table table-bordered table-sm table-hover">
            <thead class="bg-primary text-dark">
              <tr>
                <th
                  rowspan="2"
                  class="text-center align-middle"
                >
                  อัตรจ่าย
                </th>
                <!-- <th
                  rowspan="2"
                  class="text-center align-middle"
                >
                  เปิด/ปิด
                </th> -->
                <th rowspan="2" />
                <th colspan="7">
                  <button
                    type="button"
                    class="btn mr-1 tx-13 btn-primary btn-sm"
                  >
                    เลขท้าย
                  </button><button
                    type="button"
                    class="btn mr-1 tx-13 btn-light btn-sm"
                  >
                    เลขหัว
                  </button><button
                    type="button"
                    class="btn mr-1 tx-13 btn-light btn-sm"
                  >
                    เลข 4-6 ตัว
                  </button><button
                    type="button"
                    class="btn mr-1 tx-13 btn-light btn-sm"
                  >
                    เลขคู่คี่
                  </button>
                </th>
              </tr>
              <tr>
                <th class="text-center">
                  3 ตัวบน
                </th>
                <th class="text-center">
                  3 ตัวล่าง
                </th>
                <th class="text-center">
                  3 ตัวโต๊ด
                </th>
                <th class="text-center">
                  2 ตัวบน
                </th>
                <th class="text-center">
                  2 ตัวล่าง
                </th>
                <th class="text-center">
                  วิ่งบน
                </th>
                <th class="text-center">
                  วิ่งล่าง
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th
                  scope="col"
                  rowspan="3"
                  class="text-center align-middle"
                >
                  ตั้งค่าอัตราจ่ายทั้งหมด
                </th>
                <!-- <td
                  rowspan="3"
                  class="text-center align-middle"
                >
                  <div class="custom-control custom-switch">
                    <input
                      id="__BVID__462"
                      type="checkbox"
                      class="custom-control-input is-invalid"
                      value="true"
                    ><label
                      class="custom-control-label"
                      for="__BVID__462"
                    >ปิด</label>
                  </div>
                </td> -->
                <td>จ่าย</td>
                <td class="py-2">
                  <input
                    v-model="SettingDetail.rate_u3"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.rate_u3 = CalMinMax(SettingDetail.rate_u3 , 0 , FindMinAll(FixRate,'rate_u3'))"
                  >
                </td>
                <td class="py-2">
                  <input
                    v-model="SettingDetail.rate_d3"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.rate_d3 = CalMinMax(SettingDetail.rate_d3 , 0 , FindMinAll(FixRate,'rate_d3'))"
                  >
                </td>
                <td class="py-2">
                  <input
                    v-model="SettingDetail.rate_t3"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.rate_t3 = CalMinMax(SettingDetail.rate_t3 , 0 , FindMinAll(FixRate,'rate_t3'))"
                  >
                </td>
                <td class="py-2">
                  <input
                    v-model="SettingDetail.rate_u2"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.rate_u2 = CalMinMax(SettingDetail.rate_u2 , 0 , FindMinAll(FixRate,'rate_u2'))"
                  >
                </td>
                <td class="py-2">
                  <input
                    v-model="SettingDetail.rate_d2"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.rate_d2 = CalMinMax(SettingDetail.rate_d2 , 0 , FindMinAll(FixRate,'rate_d2'))"
                  >
                </td>
                <td class="py-2">
                  <input
                    v-model="SettingDetail.rate_u1"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.rate_u1 = CalMinMax(SettingDetail.rate_u1 , 0 , FindMinAll(FixRate,'rate_u1'))"
                  >
                </td>
                <td class="py-2">
                  <input
                    v-model="SettingDetail.rate_d1"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.rate_d1 = CalMinMax(SettingDetail.rate_d1 , 0 , FindMinAll(FixRate,'rate_d1'))"
                  >
                </td>
              </tr>
              <tr>
                <td>คอม</td>
                <td class="py-2">
                  <input
                    v-model="SettingDetail.com_u3"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.com_u3 = CalMinMax(SettingDetail.com_u3 , 0 , FindMinAll(FixRate,'com_u3'))"
                  >
                </td>
                <td class="py-2">
                  <input
                    v-model="SettingDetail.com_d3"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.com_d3 = CalMinMax(SettingDetail.com_d3 , 0 , FindMinAll(FixRate,'com_d3'))"
                  >
                </td>
                <td class="py-2">
                  <input
                    v-model="SettingDetail.com_t3"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.com_t3 = CalMinMax(SettingDetail.com_t3 , 0 , FindMinAll(FixRate,'com_t3'))"
                  >
                </td>
                <td class="py-2">
                  <input
                    v-model="SettingDetail.com_u2"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.com_u2 = CalMinMax(SettingDetail.com_u2 , 0 , FindMinAll(FixRate,'com_u2'))"
                  >
                </td>
                <td class="py-2">
                  <input
                    v-model="SettingDetail.com_d2"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.com_d2 = CalMinMax(SettingDetail.com_d2 , 0 , FindMinAll(FixRate,'com_d2'))"
                  >
                </td>
                <td class="py-2">
                  <input
                    v-model="SettingDetail.com_u1"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.com_u1 = CalMinMax(SettingDetail.com_u1 , 0 , FindMinAll(FixRate,'com_u1'))"
                  >
                </td>
                <td class="py-2">
                  <input
                    v-model="SettingDetail.com_d1"
                    type="number"
                    class="form-control"
                    @input="SettingDetail.com_d1 = CalMinMax(SettingDetail.com_d1 , 0 , FindMinAll(FixRate,'com_d1'))"
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="!SettingRateAll">
          <div
            v-for="(items, index) in RateArr"
            :key="index"
          >
            <h3 class="font-weight-bolder font-medium-3 mt-1">
              {{ items.group_name }}
            </h3>
            <table class="table table-bordered table-sm table-hover">
              <thead>
                <tr>
                  <th
                    rowspan="2"
                    class="text-center align-middle"
                  >
                    อัตรจ่าย
                  </th>
                  <!-- <th
                    rowspan="2"
                    class="text-center align-middle"
                  >
                    เปิด/ปิด
                  </th> -->
                  <th rowspan="2" />
                  <th
                    colspan="7"
                    width="70%"
                  >
                    <button
                      type="button"
                      class="btn mr-1 tx-13 btn-primary btn-sm"
                    >
                      เลขท้าย
                    </button><button
                      type="button"
                      class="btn mr-1 tx-13 btn-light btn-sm"
                    >
                      เลขหัว
                    </button><button
                      type="button"
                      class="btn mr-1 tx-13 btn-light btn-sm"
                    >
                      เลข 4-6 ตัว
                    </button><button
                      type="button"
                      class="btn mr-1 tx-13 btn-light btn-sm"
                    >
                      เลขคู่คี่
                    </button>
                  </th>
                </tr>
                <tr>
                  <th class="text-center">
                    3 ตัวบน
                  </th>
                  <th class="text-center">
                    3 ตัวล่าง
                  </th>
                  <th class="text-center">
                    3 ตัวโต๊ด
                  </th>
                  <th class="text-center">
                    2 ตัวบน
                  </th>
                  <th class="text-center">
                    2 ตัวล่าง
                  </th>
                  <th class="text-center">
                    วิ่งบน
                  </th>
                  <th class="text-center">
                    วิ่งล่าง
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="">
                  <th
                    scope="col"
                    rowspan="3"
                    class="text-center align-middle"
                  >
                    อัตราจ่ายเริ่มต้น
                  </th>
                  <!-- <td
                    rowspan="3"
                    class="text-center align-middle"
                  >
                    <div class="custom-control custom-switch">
                      <input
                        id="__BVID__431"
                        type="checkbox"
                        class="custom-control-input is-valid"
                        value="true"
                      ><label
                        class="custom-control-label"
                        for="__BVID__431"
                      >เปิด</label>
                    </div>
                  </td> -->
                  <td>จ่าย</td>
                  <td class="py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__432"
                        v-model="items.rate_u3"
                        type="number"
                        min="0"
                        max="90"
                        :disabled="FixRate[index].rate_u3 === 0"
                        class="form-control"
                        @input="items.rate_u3 = CalMinMax(items.rate_u3 , 0 , FixRate[index].rate_u3)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].rate_u3 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__433"
                        v-model="items.rate_d3"
                        type="number"
                        min="0"
                        max="145"
                        :disabled="FixRate[index].rate_d3 === 0"
                        class="form-control"
                        @input="items.rate_d3 = CalMinMax(items.rate_d3 , 0 , FixRate[index].rate_d3)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].rate_d3 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__434"
                        v-model="items.rate_t3"
                        type="number"
                        min="0"
                        max="4"
                        :disabled="FixRate[index].rate_t3 === 0"
                        class="form-control"
                        @input="items.rate_t3 = CalMinMax(items.rate_t3 , 0 , FixRate[index].rate_t3)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].rate_t3 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__435"
                        v-model="items.rate_u2"
                        type="number"
                        min="0"
                        max="125"
                        :disabled="FixRate[index].rate_u2 === 0"
                        class="form-control"
                        @input="items.rate_u2 = CalMinMax(items.rate_u2 , 0 , FixRate[index].rate_u2)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].rate_u2 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__436"
                        v-model="items.rate_d2"
                        type="number"
                        min="0"
                        max="90"
                        :disabled="FixRate[index].rate_d2 === 0"
                        class="form-control"
                        @input="items.rate_d2 = CalMinMax(items.rate_d2 , 0 , FixRate[index].rate_d2)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].rate_d2 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__437"
                        v-model="items.rate_u1"
                        type="number"
                        min="0"
                        max="750"
                        :disabled="FixRate[index].rate_u1 === 0"
                        class="form-control"
                        @input="items.rate_u1 = CalMinMax(items.rate_u1 , 0 , FixRate[index].rate_u1)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].rate_u1 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__438"
                        v-model="items.rate_d1"
                        type="number"
                        min="0"
                        max="3"
                        :disabled="FixRate[index].rate_d1 === 0"
                        class="form-control"
                        @input="items.rate_d1 = CalMinMax(items.rate_d1 , 0 , FixRate[index].rate_d1)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].rate_d1 }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr class="">
                  <td>คอม</td>
                  <td class="py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__439"
                        v-model="items.com_u3"
                        type="number"
                        min="0"
                        max="8"
                        :disabled="FixRate[index].com_u3 === 0"
                        class="form-control"
                        @input="items.com_u3 = CalMinMax(items.com_u3 , 0 , FixRate[index].com_u3)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].com_u3 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__440"
                        v-model="items.com_d3"
                        type="number"
                        min="0"
                        max="15"
                        :disabled="FixRate[index].com_d3 === 0"
                        class="form-control"
                        @input="items.com_d3 = CalMinMax(items.com_d3 , 0 , FixRate[index].com_d3)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].com_d3 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__441"
                        v-model="items.com_t3"
                        type="number"
                        min="0"
                        max="12"
                        :disabled="FixRate[index].com_t3 === 0"
                        class="form-control"
                        @input="items.com_t3 = CalMinMax(items.com_t3 , 0 , FixRate[index].com_t3)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].com_t3 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__442"
                        v-model="items.com_u2"
                        type="number"
                        min="0"
                        max="15"
                        :disabled="FixRate[index].com_u2 === 0"
                        class="form-control"
                        @input="items.com_u2 = CalMinMax(items.com_u2 , 0 , FixRate[index].com_u2)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].com_u2 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__443"
                        v-model="items.com_d2"
                        type="number"
                        min="0"
                        max="8"
                        :disabled="FixRate[index].com_d2 === 0"
                        class="form-control"
                        @input="items.com_d2 = CalMinMax(items.com_d2 , 0 , FixRate[index].com_d2)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].com_d2 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__444"
                        v-model="items.com_u1"
                        type="number"
                        min="0"
                        max="15"
                        :disabled="FixRate[index].com_u1 === 0"
                        class="form-control"
                        @input="items.com_u1 = CalMinMax(items.com_u1 , 0 , FixRate[index].com_u1)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].com_u1 }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="py-2">
                    <div
                      role="group"
                      class="input-group input-group-sm"
                    >
                      <input
                        id="__BVID__445"
                        v-model="items.com_d1"
                        type="number"
                        min="0"
                        max="12"
                        :disabled="FixRate[index].com_d1 === 0"
                        class="form-control"
                        @input="items.com_d1 = CalMinMax(items.com_d1 , 0 , FixRate[index].com_d1)"
                      >
                      <div class="input-group-append">
                        <div class="input-group-text">
                          {{ FixRate[index].com_d1 }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <div>
          <h3 class="font-weight-bolder font-medium-3 mt-1">
            รัฐบาล
          </h3>
          <table class="table table-bordered table-sm table-hover">
            <thead>
              <tr>
                <th
                  rowspan="2"
                  class="text-center align-middle"
                >
                  อัตรจ่าย
                </th>
                <th
                  rowspan="2"
                  class="text-center align-middle"
                >
                  เปิด/ปิด
                </th>
                <th rowspan="2" />
                <th
                  colspan="7"
                  width="70%"
                >
                  <button
                    type="button"
                    class="btn mr-1 tx-13 btn-primary btn-sm"
                  >
                    เลขท้าย
                  </button><button
                    type="button"
                    class="btn mr-1 tx-13 btn-light btn-sm"
                  >
                    เลขหัว
                  </button><button
                    type="button"
                    class="btn mr-1 tx-13 btn-light btn-sm"
                  >
                    เลข 4-6 ตัว
                  </button><button
                    type="button"
                    class="btn mr-1 tx-13 btn-light btn-sm"
                  >
                    เลขคู่คี่
                  </button>
                </th>
              </tr>
              <tr>
                <th class="text-center">
                  2 ตัวล่าง
                </th>
                <th class="text-center">
                  3 ตัวล่าง
                </th>
                <th class="text-center">
                  วิ่งล่าง
                </th>
                <th class="text-center">
                  3 ตัวโต๊ด
                </th>
                <th class="text-center">
                  2 ตัวบน
                </th>
                <th class="text-center">
                  3 ตัวบน
                </th>
                <th class="text-center">
                  วิ่งบน
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="">
                <th
                  scope="col"
                  rowspan="3"
                  class="text-center align-middle"
                >
                  อัตราจ่ายเริ่มต้น
                </th>
                <td
                  rowspan="3"
                  class="text-center align-middle"
                >
                  <div class="custom-control custom-switch">
                    <input
                      id="__BVID__431"
                      type="checkbox"
                      class="custom-control-input is-valid"
                      value="true"
                    ><label
                      class="custom-control-label"
                      for="__BVID__431"
                    >เปิด</label>
                  </div>
                </td>
                <td>จ่าย</td>
                <td class="py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__432"
                      type="number"
                      min="0"
                      max="90"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        90
                      </div>
                    </div>
                  </div>
                </td>
                <td class="py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__433"
                      type="number"
                      min="0"
                      max="145"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        145
                      </div>
                    </div>
                  </div>
                </td>
                <td class="py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__434"
                      type="number"
                      min="0"
                      max="4"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        4
                      </div>
                    </div>
                  </div>
                </td>
                <td class="py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__435"
                      type="number"
                      min="0"
                      max="125"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        125
                      </div>
                    </div>
                  </div>
                </td>
                <td class="py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__436"
                      type="number"
                      min="0"
                      max="90"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        90
                      </div>
                    </div>
                  </div>
                </td>
                <td class="py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__437"
                      type="number"
                      min="0"
                      max="750"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        750
                      </div>
                    </div>
                  </div>
                </td>
                <td class="py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__438"
                      type="number"
                      min="0"
                      max="3"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        3
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="">
                <td>คอม</td>
                <td class="py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__439"
                      type="number"
                      min="0"
                      max="8"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        8
                      </div>
                    </div>
                  </div>
                </td>
                <td class="py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__440"
                      type="number"
                      min="0"
                      max="15"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        15
                      </div>
                    </div>
                  </div>
                </td>
                <td class="py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__441"
                      type="number"
                      min="0"
                      max="12"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        12
                      </div>
                    </div>
                  </div>
                </td>
                <td class="py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__442"
                      type="number"
                      min="0"
                      max="15"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        15
                      </div>
                    </div>
                  </div>
                </td>
                <td class="py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__443"
                      type="number"
                      min="0"
                      max="8"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        8
                      </div>
                    </div>
                  </div>
                </td>
                <td class="py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__444"
                      type="number"
                      min="0"
                      max="15"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        15
                      </div>
                    </div>
                  </div>
                </td>
                <td class="py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__445"
                      type="number"
                      min="0"
                      max="12"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        12
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr class="">
                <th
                  scope="col"
                  rowspan="3"
                  class="text-center align-middle"
                >
                  หวย 70
                </th>
                <td
                  rowspan="3"
                  class="text-center align-middle"
                >
                  <div class="custom-control custom-switch">
                    <input
                      id="__BVID__446"
                      type="checkbox"
                      class="custom-control-input is-valid"
                      value="true"
                    ><label
                      class="custom-control-label"
                      for="__BVID__446"
                    >เปิด</label>
                  </div>
                </td>
                <td>จ่าย</td>
                <td class="py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__447"
                      type="number"
                      min="0"
                      max="70"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        70
                      </div>
                    </div>
                  </div>
                </td>
                <td class="py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__448"
                      type="number"
                      min="0"
                      max="125"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        125
                      </div>
                    </div>
                  </div>
                </td>
                <td class="py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__449"
                      type="number"
                      min="0"
                      max="4"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        4
                      </div>
                    </div>
                  </div>
                </td>
                <td class="py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__450"
                      type="number"
                      min="0"
                      max="105"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        105
                      </div>
                    </div>
                  </div>
                </td>
                <td class="py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__451"
                      type="number"
                      min="0"
                      max="70"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        70
                      </div>
                    </div>
                  </div>
                </td>
                <td class="py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__452"
                      type="number"
                      min="0"
                      max="550"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        550
                      </div>
                    </div>
                  </div>
                </td>
                <td class="py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__453"
                      type="number"
                      min="0"
                      max="3"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        3
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="">
                <td>คอม</td>
                <td class="py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__454"
                      type="number"
                      min="0"
                      max="28"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        28
                      </div>
                    </div>
                  </div>
                </td>
                <td class="py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__455"
                      type="number"
                      min="0"
                      max="33"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        33
                      </div>
                    </div>
                  </div>
                </td>
                <td class="py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__456"
                      type="number"
                      min="0"
                      max="12"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        12
                      </div>
                    </div>
                  </div>
                </td>
                <td class="py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__457"
                      type="number"
                      min="0"
                      max="33"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        33
                      </div>
                    </div>
                  </div>
                </td>
                <td class="py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__458"
                      type="number"
                      min="0"
                      max="28"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        28
                      </div>
                    </div>
                  </div>
                </td>
                <td class="py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__459"
                      type="number"
                      min="0"
                      max="33"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        33
                      </div>
                    </div>
                  </div>
                </td>
                <td class="py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__460"
                      type="number"
                      min="0"
                      max="12"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        12
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div> -->
      </div>
      <div class="mt-1">
        <b-button
          variant="primary"
          type="submit"
          @click.prevent="validationForm"
        >
          บันทึกข้อมูล
        </b-button>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BButton, BCard, BFormCheckbox,
  BOverlay,
  BIconController,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BCard,
    BFormCheckbox,
    vSelect,
    BOverlay,
    BIconController,
  },
  data() {
    return {
      OverLay: false,
      currentTab: 0,
      tabs: ['เปิด/ปิดกลุ่มหวย', 'ข้อมูลการแทง', 'อัตราจ่าย'],
      status: false,
      setShare: false,
      name: '',
      lastname: '',
      tel: '',
      line_id: '',
      PasswordValue: '',
      passwordCon: '',
      copy_by: '',
      share_agent: 80,
      excess_percent: 0,
      huay_status: 0,
      currency: 'THB',
      userOp: [
        { title: 'Admin', value: 'admin' },
        { title: 'User', value: 'user' },
      ],
      excessOp: [
        { value: 0, text: '0.00%' },
        { value: 80, text: '80%' },
        { value: 90, text: '90%' },
        { value: 100, text: '100%' },
      ],
      currencyOp: [
        { value: 'THB', text: 'THB' },
        { value: 'USD', text: 'USD' },
      ],
      excess: 0,
      required,
      LottoGroup: [],
      FixRate: [],
      SettingTangAll: false,
      TangArr: [],
      SettingRateAll: false,
      RateArr: [],
      SettingDetailTools: [],
      SettingDetailAll: {
        Minall: null,
        Maxall: null,
        MaxNumall: null,
        MaxTimeall: null,
      },
      SettingDetail: {
        rate_u3: 0,
        rate_d3: 0,
        rate_t3: 0,
        rate_u2: 0,
        rate_d2: 0,
        rate_t2: 0,
        rate_u1: 0,
        rate_d1: 0,
        com_u3: 0,
        com_d3: 0,
        com_t3: 0,
        com_u2: 0,
        com_d2: 0,
        com_t2: 0,
        com_u1: 0,
        com_d1: 0,
        min_u3: 0,
        min_d3: 0,
        min_t3: 0,
        min_u2: 0,
        min_d2: 0,
        min_t2: 0,
        min_u1: 0,
        min_d1: 0,
        max_u3: 0,
        max_d3: 0,
        max_t3: 0,
        max_u2: 0,
        max_d2: 0,
        max_t2: 0,
        max_u1: 0,
        max_d1: 0,
        maxnum_u3: 0,
        maxnum_d3: 0,
        maxnum_t3: 0,
        maxnum_u2: 0,
        maxnum_d2: 0,
        maxnum_t2: 0,
        maxnum_u1: 0,
        maxnum_d1: 0,
        maxtime_u3: 0,
        maxtime_d3: 0,
        maxtime_t3: 0,
        maxtime_u2: 0,
        maxtime_d2: 0,
        maxtime_t2: 0,
        maxtime_u1: 0,
        maxtime_d1: 0,
      },
    }
  },
  mounted() {
    this.GetGroup()
  },
  methods: {
    async validationForm() {
      // this.$refs.simpleRules.validate().then(success => {
      //   if (success) {
      //     // eslint-disable-next-line
      //     alert('form submitted!')
      //   }
      // })
      if (this.SettingTangAll) {
        this.LottoGroup.forEach((item, index) => {
          this.LottoGroup[index].min_u3 = this.SettingDetail.min_u3
          this.LottoGroup[index].min_d3 = this.SettingDetail.min_d3
          this.LottoGroup[index].min_t3 = this.SettingDetail.min_t3
          this.LottoGroup[index].min_u2 = this.SettingDetail.min_u2
          this.LottoGroup[index].min_d2 = this.SettingDetail.min_d2
          this.LottoGroup[index].min_u1 = this.SettingDetail.min_u1
          this.LottoGroup[index].min_d1 = this.SettingDetail.min_d1
          this.LottoGroup[index].max_u3 = this.SettingDetail.max_u3
          this.LottoGroup[index].max_d3 = this.SettingDetail.max_d3
          this.LottoGroup[index].max_t3 = this.SettingDetail.max_t3
          this.LottoGroup[index].max_u2 = this.SettingDetail.max_u2
          this.LottoGroup[index].max_d2 = this.SettingDetail.max_d2
          this.LottoGroup[index].max_u1 = this.SettingDetail.max_u1
          this.LottoGroup[index].max_d1 = this.SettingDetail.max_d1
          this.LottoGroup[index].maxnum_u3 = this.SettingDetail.maxnum_u3
          this.LottoGroup[index].maxnum_d3 = this.SettingDetail.maxnum_d3
          this.LottoGroup[index].maxnum_t3 = this.SettingDetail.maxnum_t3
          this.LottoGroup[index].maxnum_u2 = this.SettingDetail.maxnum_u2
          this.LottoGroup[index].maxnum_d2 = this.SettingDetail.maxnum_d2
          this.LottoGroup[index].maxnum_u1 = this.SettingDetail.maxnum_u1
          this.LottoGroup[index].maxnum_d1 = this.SettingDetail.maxnum_d1
          this.LottoGroup[index].maxtime_u3 = this.SettingDetail.maxtime_u3
          this.LottoGroup[index].maxtime_d3 = this.SettingDetail.maxtime_d3
          this.LottoGroup[index].maxtime_t3 = this.SettingDetail.maxtime_t3
          this.LottoGroup[index].maxtime_u2 = this.SettingDetail.maxtime_u2
          this.LottoGroup[index].maxtime_d2 = this.SettingDetail.maxtime_d2
          this.LottoGroup[index].maxtime_u1 = this.SettingDetail.maxtime_u1
          this.LottoGroup[index].maxtime_d1 = this.SettingDetail.maxtime_d1
        })
      }
      if (this.SettingRateAll) {
        this.LottoGroup.forEach((item, index) => {
          this.LottoGroup[index].rate_u3 = this.SettingDetail.rate_u3
          this.LottoGroup[index].rate_d3 = this.SettingDetail.rate_d3
          this.LottoGroup[index].rate_t3 = this.SettingDetail.rate_t3
          this.LottoGroup[index].rate_u2 = this.SettingDetail.rate_u2
          this.LottoGroup[index].rate_d2 = this.SettingDetail.rate_d2
          this.LottoGroup[index].rate_u1 = this.SettingDetail.rate_u1
          this.LottoGroup[index].rate_d1 = this.SettingDetail.rate_d1
          this.LottoGroup[index].com_u3 = this.SettingDetail.com_u3
          this.LottoGroup[index].com_d3 = this.SettingDetail.com_d3
          this.LottoGroup[index].com_t3 = this.SettingDetail.com_t3
          this.LottoGroup[index].com_u2 = this.SettingDetail.com_u2
          this.LottoGroup[index].com_d2 = this.SettingDetail.com_d2
          this.LottoGroup[index].com_u1 = this.SettingDetail.com_u1
          this.LottoGroup[index].com_d1 = this.SettingDetail.com_d1
        })
      }
      // console.log(this.LottoGroup)
      this.OverLay = true
      try {
        await this.$http.put('lotto/ag/group/update', this.LottoGroup)
        this.Success('บันทึกข้อมูลสำเร็จ')
        this.OverLay = false
      } catch (e) {
        console.log(e)
        this.Error('บันทึกข้อมูลไม่สำเร็จ')
        this.OverLay = false
      }
    },
    async GetGroup() {
      try {
        const res = await this.$http.get('lotto/ag/group')
        const resrate = await this.$http.get('lotto/ag/fristrate')
        this.LottoGroup = res.data
        this.FixRate = resrate.data
        for (let i = 0; i < this.LottoGroup.length; i += 1) {
          this.SettingDetailTools.push({
            Minall: null,
            Maxall: null,
            MaxNumall: null,
            MaxTimeall: null,
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    AllSetGroup() {
      this.LottoGroup.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.status = this.huay_status
      })
    },
    SelectTangAll() {
      this.TangArr = []
      this.LottoGroup.forEach(item => {
        this.TangArr.push(item)
      })
    },
    SelectRateAll() {
      this.RateArr = []
      this.LottoGroup.forEach(item => {
        this.RateArr.push(item)
      })
    },
    CalMinMax(ele, min, max) {
      if (ele < min) {
        // eslint-disable-next-line no-param-reassign
        return min
      } if (ele > max) {
        // eslint-disable-next-line no-param-reassign
        return max
      }
      return ele
    },
    CalAutopushAll(num, Arr) {
      const RateArr = []
      Arr.forEach(item => {
        RateArr.push(this.FindMinAll(this.FixRate, item))
      })
      const resmin = this.FindMin(RateArr)
      let resmax = num
      if (num > resmin) {
        resmax = resmin
      }
      Arr.forEach(item => {
        this.SettingDetail[item] = resmax
      })
      return resmax
    },
    CalAutopush(num, index, Arr) {
      const RateArr = []
      Arr.forEach(item => {
        RateArr.push(this.FixRate[index][item])
      })
      const resmin = this.FindMin(RateArr)
      let resmax = num
      if (num > resmin) {
        resmax = resmin
      }
      Arr.forEach(item => {
        this.LottoGroup[index][item] = resmax
      })
      return resmax
    },
    ButtonMax(Arr) {
      Arr.forEach(item => {
        this.SettingDetail[item] = this.FindMinAll(this.FixRate, item)
      })
    },
    FindMin(obj) {
      // eslint-disable-next-line prefer-spread
      const min = Math.min.apply(Math, obj)
      return min
    },
    FindMinAll(obj, key) {
      // eslint-disable-next-line prefer-spread
      const min = Math.min.apply(Math, obj.map(o => o[key]))
      return min
    },
    Success(title = '', mes = '') {
      this.$swal({
        icon: 'success',
        title: `<h3 style="color: #141414">${title}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    Error(title = '', mes = '') {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${title}!</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.active {
  background-color: #0068d0 !important;
  color: #fff !important;
}

.input-group-text {
  background-color: #0068d0 !important;
  color: #fff !important;
}
</style>
